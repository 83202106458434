<template>
  <div class="mt-3">
    <b-form @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-lg-6">
          <b-form-group label="Pilih Divisi">
            <b-form-select :class="$v.form.id_devisi.$error ? 'is-invalid' : ''" name="id_devisi" v-model="form.id_devisi"
              :options="dataDivisi.options"></b-form-select>
            <small v-if="$v.form.id_devisi.$error" class="text-danger">
              Kolom tidak boleh kosong
            </small>
          </b-form-group>
        </div>
        <div class="col-lg-6">
          <b-form-group label="Pilih Cabang">
            <b-form-select :class="$v.form.id_cabang.$error ? 'is-invalid' : ''" v-model="form.id_cabang"
              :options="dataCabang.options"></b-form-select>
            <small v-if="!$v.form.id_cabang.required && $v.form.id_cabang.$dirty" class="text-danger">
              Kolom tidak boleh kosong
            </small>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <b-form-group id="fg-create-sub-category-fpp" label="Pilih Item" label-for="create-sub-category-fpp"
            class="required">
            <multiselect @select="changeListItem" :disabled="false" :loading="false" placeholder="Pilih Item" label="text"
              track-by="id" v-model="form.id_kategori_sub_fpp" :show-labels="false" :close-on-select="true"
              :multiple="false" :options="data_kategori_jenis">
            </multiselect>
            <small v-if="!$v.form.id_kategori_sub_fpp.required && $v.form.id_kategori_sub_fpp.$dirty" class="text-danger">
              Kolom tidak boleh kosong
            </small>
          </b-form-group>
        </div>
        <div class="col-lg-4">
          <b-form-group label="Jumlah">
            <b-form-input v-model="form.qty" type="number" placeholder="Contoh : 1,2,3"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <b-form-group label="Catatan/Kronologi" class="required">
        <textarea v-on:input="check" v-model="form.catatan"
          :class="$v.form.id_kategori_sub_fpp.$error ? 'is-invalid' : ''" class="textarea form-control"
          placeholder="Catatan" :rows="4"></textarea>
        <small v-if="$v.form.catatan.$error" class="text-danger">
          Kolom tidak bolehkosong
        </small>
        <p class="text-secondary mt-1" :class="{ help: true, 'text-danger': remaining == 0 }">
          {{ instruction }}
        </p>
      </b-form-group>
      <div class="row">
        <div class="col-md-8">
          <span class="mb-1 d-block">Video Pendukung</span>
          <div class="form-group wrapper-video-view">
            <label for="video">
              <input @change="handleChangeVideo" type="file" id="video" class="d-none" accept="video/*">
              <div class="icon-choose-video d-nonex">
                <i class="fa fa-file-video-o icon-choose"></i>
              </div>
            </label>
            <div v-if="videoPreview" class="video-preview">
              <button @click="removeVideoSelected" class="btn-remove-video">
                <i class="fa fa-times"></i>
              </button>
              <video controls>
                <source :src="videoPreview" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-1">Foto Pendukung</div>
        <div class="col-6 col-md-4 mb-3" v-for="item in imagePreview" :key="item.preview">
          <div class="image-preview-container">
            <button @click="removePreviewImage(item.preview, item.file)" class="btn-remove-image">
              <i class="fa fa-times"></i>
            </button>
            <expandable-image class="image-preview" :src="item.preview" />
          </div>
        </div>
        <div class="col-6 col-md-4 mb-3">
          <label for="images" class="choose-image-action">
            <input type="file" accept="image/*" id="images" class="d-none" multiple @change="handleChangeImages">
            <i class="fa fa-image icon-choose"></i>
          </label>
        </div>
      </div>
      <p v-if="fileErrorMessage !== ''" class="text-danger">{{ fileErrorMessage }}</p>
      <div class="d-flex justify-content-end">
        <button :disabled="isLoading" class="btn btn-primary ml-2">
          <i v-if="!isLoading" class="fa fa-upload"></i>
          <i v-if="isLoading" class="fa fa-spinner fa-spin"></i>
          Ajukan
        </button>
      </div>
    </b-form>
  </div>
</template>

<script>
import axiosClient from "../../config/api";
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  name: "FormPengajuan",
  props: {
    type: null,
    data_kategori_jenis: []
  },
  data() {
    return {
      isLoading: false,
      limitCatatan: 100,
      fileErrorMessage: "",
      dataCabang: {
        options: []
      },
      dataDivisi: {
        options: []
      },
      videoPreview: null,
      imagePreview: [],
      form: {
        id_kategori_jenis_fpp: this.type,
        id_kategori_sub_fpp: null,
        id_cabang: null,
        id_devisi: null,
        qty: null,
        catatan: "",
        files: [],
        video: [],
      }
    }
  },
  validations: {
    form: {
      id_devisi: {
        required,
      },
      id_cabang: {
        required,
      },
      id_kategori_sub_fpp: {
        required,
      },
      catatan: {
        required,

      }
    },
  },
  methods: {
    async getCabang() {
      const cabang = localStorage.getItem("cabang")
      const cabangJson = JSON.parse(cabang)
      const opt = cabangJson.map(item => {
        return {
          value: item.id,
          text: item.name,
        }
      })
      this.dataCabang.options = [{ value: null, text: "Pilih Cabang", disabled: true }, ...opt]
    },
    async getDivisi() {
      const response = await this.$store.dispatch("getDivisi")
      this.dataDivisi.options = [{ value: null, text: "Pilih Divisi", disabled: true }, ...response]
    },
    handleChangeVideo(e) {
      this.fileErrorMessage = ''
      const videoFile = e.target.files[0]
      this.form.video = e.target.files
      this.createPreviewVideo(videoFile)
    },
    handleChangeImages(e) {
      this.fileErrorMessage = ''
      const files = e.target.files;
      this.createPreviewImage(files)
    },
    createPreviewImage(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.form.files.push(file);
        let blobURL = URL.createObjectURL(file);
        this.imagePreview.push({
          preview: blobURL,
          file: file
        })
      }
    },
    createPreviewVideo(file) {
      let blobURL = URL.createObjectURL(file);
      this.videoPreview = blobURL
    },
    removeVideoSelected() {
      this.videoPreview = null
      this.form.video = []
    },
    removePreviewImage(preview, file) {
      const newPreview = this.imagePreview.filter(i => i.preview !== preview)
      const newFile = []
      for (let i = 0; i < this.form.files.length; i++) {
        const item = this.form.files[i];
        if (item === file) {
          newFile.push(item)
        }
      }
      this.imagePreview = newPreview
      this.form.files = newFile

      // console.log(newPreview.length)
    },
    changeListItem(val) {
      this.form.id_kategori_sub_fpp = val.id;
    },
    handleSubmit: function () {
      // this.isLoading = true
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        window.scrollTo(0, 0);
        this.$helper.toastErr(this, "Silahkan lengkapi form pengajuan!")
        this.isLoading = false
        return;
      }
      const isVideo = this.form.video.length > 0
      const isImages = this.form.files.length > 0

      // console.log(this.form.video.length, "isVideo")
      // console.log(this.form.files.length, "isImages")
      // console.log(this.form.files, "i")
      // return
      if (isVideo || isImages) {
        this.showSweetAlertCreate();
      } else {
        this.fileErrorMessage = 'Silahkan pilih foto atau video!'
        this.isLoading = false
        return;
      }
    },
    showSweetAlertCreate: function () {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      this.$swal({
        title: "Konfirmasi",
        html: "Apakah Anda yakin pengajuan sudah sesuai?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "(Ya) Ajukan",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let form = new FormData();
          form.append('id_kategori_jenis_fpp', this.type);
          form.append('id_kategori_sub_fpp', this.form.id_kategori_sub_fpp.id);
          form.append('id_cabang', this.form.id_cabang);
          form.append('id_devisi', this.form.id_devisi);
          form.append('catatan', this.form.catatan);
          form.append('qty', this.form.qty ? this.form.qty : 0);
          form.append('videos[0]', this.form.video[0]);
          this.form.files.forEach((item, index) => {
            form.append(`files[${index}]`, item);
          });

          this.$swal({
            title: "Proses",
            html: "Pengajuan anda sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });
          try {
            const response = await axiosClient({
              url: '/internal-memo/memo/store-2/test',
              method: 'POST',
              headers: {
                "Content-Type": "multipart/form-data",
              },
              data: form
            })
            if (response.status === 200) {
              Toast.fire({
                iconHtml: `<div class="m-2"> <i class="fa fa-check"></i></div>`,
                iconColor: '#a5dc86',
                html: "Selamat! Pengajuan Anda berhasil terkirim",
              }).then(() => {
                window.location = location.origin + "/daftar-pengajuan";
              });
            }
          } catch (error) {
            Toast.fire({
              iconHtml: `<div><i class="fa fa-times"></i></div>`,
              iconColor: '#dc3545',
              html: error.response.data.data || "Pengajuan Anda gagal terkirim",
            });
          }
        }
        this.isLoading = false
      });
    },
    // Check 100 Character Function
    check: function () {
      this.form.catatan = this.form.catatan.substr(0, this.limitCatatan);
    },
  },
  computed: {
    instruction: function () {
      return this.form.catatan == ""
        ? "Maksimal: " + this.limitCatatan + " Karakter"
        : "Tersisa: " + this.remaining + " Karakter";
    },

    remaining: function () {
      return this.limitCatatan - this.form.catatan.length;
    },
  },
  created() {
    this.getCabang();
    this.getDivisi()
  }
}
</script>

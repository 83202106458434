<template>
  <div class="animated fadeIn">
    <div class="row justify-content-start">
      <div class="col-lg-12">
        <b-card>
          <template v-slot:header>
            <h5>Form Pengajuan </h5>
          </template>
          <div class="row">
            <div class="col-lg-6">
              <div>
                <b-form-group label="Pilih Type Pengajuan">
                  <b-form-select @change="handleChangeType" v-model="typePengajuan.selected"
                    :options="typePengajuan.options"></b-form-select>
                </b-form-group>
              </div>
              <FormPengajuan :data_kategori_jenis="dataKategoriJenis" :type="typePengajuan.selected" />
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import FormPengajuan from '../../components/pengajuan/FormPengajuan.vue';
export default {
  components: {
    FormPengajuan
  },
  data() {
    return {
      typePengajuan: {
        selected: null,
        options: []
      },
      dataKategoriJenis: [],
      browser: null,
      version: null,
    }
  },
  methods: {
    async getJenisPengajuan() {
      const response = await this.$store.dispatch("getJenisPengajuan")
      this.typePengajuan.options = [
        { value: null, text: "Pilih Jenis Pengajuan", disabled: true }, ...response]
    },
    async handleChangeType() {
      const response = await this.$store.dispatch("getKategoryJenis", this.typePengajuan.selected)
      this.dataKategoriJenis = response;
    },
    detectBrower() {
      const b = this.$browserDetect.meta.name ? this.$browserDetect.meta.name : " tidak direkomendasikan"
      const v = this.$browserDetect.meta.version ? this.$browserDetect.meta.version : "-"
      this.browser = b
      this.version = v
    },
  },

  mounted() {
    this.detectBrower()
    this.getJenisPengajuan()
  }
}
</script>